.heading{
    font-weight: bolder;
    padding-top: 38px;
}
.p{
    word-wrap: normal;
    margin-top: 10px;
}
.p1{
    word-wrap: normal;
    margin-top: 0;
    padding-bottom: 10px;
    color: #ffffff;
}
.p2{
    word-wrap: normal;
    margin-top: 0;
}
.ul{
    font-weight: bolder;
}
.h5{
    color: #ffffff;
    text-align: center;
    font-weight: bold;
}
.text1{
    color: black;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    word-wrap: normal;
}
.image1{
    padding-bottom: 5px;
    padding-left: 30px;
}
.cardbod{
    padding-left: 0px;
    word-wrap: normal;
    font-size: 16px;
}
.cardbod2{
    padding: 0;
    margin: 0;
}
.h6{
    font-weight: bolder;
    text-decoration: underline;
    padding-bottom: 5px;
    margin-top: '50px';
}
.detailHeader{
    font-weight: bolder;
    text-decoration: underline;
    padding-bottom: 5px;
    color: #ffffff;
}
.div{
    padding-left: 15px;
    padding-right: 20px;
}
.div2{
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #363535;
    margin-right: 20px;
    margin-top: 5px;
    margin-left: 10px;
    padding-left: 10;
    padding-right: 10;
}
.div3{
    padding-top: 15px;
    margin-right: 20px;
    margin-bottom: 35px;
    padding-bottom: 10px;
}
.div4{
    padding-right: 20px;
}
.div5{
    padding-bottom: 40px;
}
.tablestyle{
    padding: 5px;
    text-align: left;
}
.tablestyle1{
    padding: 5px;
    text-align: center;
}
.code{
    color: #ffffff;
    padding-left: 5px;
    font-size: 12px;
    background-color: #363535;
}
.codestyle1{
    color: #ffffff;
    margin-left: 5px;
    font-size: 12px;
    background-color: #363535;
}
.codestyle2{
    color: #2ca8fc;
    padding-left: 10px;
    font-size: 12px;
    background-color: #363535;
}
.codestyle3{
    color: #ff4949;
    font-size: 12px;
    background-color: #363535;
}
.codestyle4{
    color:#99ff3a;
    font-size: 12px;
    word-wrap: break-word;
    background-color: #363535;
}
.codestyle5{
    color:#fc9846;
    font-size: 12px;
}